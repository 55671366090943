import axios from 'axios'
import qs from 'qs';
import {
  Message,
  MessageBox,
  Button,
  Alert
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
// import CryptoJS from 'crypto-js/crypto-js'
axios.defaults.timeout = 600000
//axios.defaults.withCredentials = true
const BASE_URL = config.VUE_APP_URL
//const BASE_URL = process.env.VUE_APP_URL //本地
axios.defaults.baseURL = BASE_URL; //接口地址
let CancelToken = axios.CancelToken //定义CancelToken的函数
let pending = [] //存放未完成的请求
//中断并移除队列中重复的请求
let removerPending = (ever) => {
  for (let p in pending) {
    if (pending[p].u === (ever.url + '&' + ever.method)) {
      pending[p].f(), //执行取消请求
        pending.splice(p, 1) //删除这条请求
    }
  }
}
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  //发送请求前取消重复的请求
  removerPending(config)
  if (token) {
    // 如果token存在，则统一添加到HTTP请求头部
    config.headers.Authorization = `Bearer ${token}`;
  }
  // 给需要的请求统一添加cancelToken,方便取消
  if (config.cancel) {
    config.cancelToken = new CancelToken((c) => {
      pending.push({
        u: config.url + '&' + config.method,
        f: c
      })
    })
  }
  if (config.method == 'post' || config.method == 'put') {
    if (config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data)
    }
  }
  return Promise.resolve(config);
}, function (error) {
  console.log(error, "error2")
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  removerPending(response)
  // 对响应数据做点什么
  return Promise.resolve(response);
}, function (error) {
  console.log(error, error.response.status, "error1")
  // 请求失败，判断状态码
  if (error.response.status === 401) {
    // 如果状态码为401，token失效，跳转到登录页面
    MessageBox.confirm('登录已过期，请重新登录！', '提示', {
      confirmButtonText: '重新登录',
      // cancelButtonText: '取消',
      showCancelButton: false,
      type: 'warning'
    }).then(() => {
      sessionStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      localStorage.removeItem('routeSiteDetail')
      // 跳转到登录页
      window.location.href = "/";
      Message({
        type: 'success',
        message: '操作成功!'
      });
    }).catch(() => {
      Message({
        type: 'info',
        message: '已取消登录'
      });
    });
  }
  // 对响应错误做点什么
  return Promise.reject(error);
});

// head请求头信息
function post(url, params, head) {
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
        headers: head
      })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }).catch(e => {
    console.log(e)
  })
}
//type是否需要田间cancelToken
function get(url, param, type) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: param,
        cancel: type
      })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }).catch(e => {
    console.log(e)
  })
}

function put(url, params, head) {
  return new Promise((resolve, reject) => {
    axios.put(url, params, {
        headers: head
      })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }).catch(e => {
    console.log(e)
  })
}

function del(url, param, type) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
        params: param,
        cancel: type
      })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  }).catch(e => {
    console.log(e)
  })
}
export {
  get,
  post,
  put,
  del

}
export default {
  url() {
    return axios.defaults.baseURL;
  },
  imgUrl() {},
  jsonUrl() {},
  // =================================================公共接口==========================================================
  // 登录方法
  getUser(params) {
    return post('/auth/login', params)
  },
  // 退出登录
  getLogout(params) {
    return post('/auth/logout', params)
  },
  // 获取用户信息
  getInfo(params) {
    return get('/system/user/getInfo', params)
  },
  // 区域树
  getAreaTree(params) {
    return get('/emqx/area/getAreaTree', params)
  },
  // 导出
  exportSummary(params) {
    return post('/system/user/export', params, )
  },
  // 重置密码发送验证码
  sendResetPasswordSms(params) {
    return post('/system/user/sendResetPasswordSms', params, )
  },
  // 重置密码
  resetPwd(params) {
    return put('/system/user/resetPwd', params, )
  },
  // 修改密码
  getEditPassword(params) {
    return get('/system/user/editPassword', params, )
  },
  //  修改手机号
  getEditPhonenumber(params) {
    return get('/system/user/editPhonenumber', params, )
  },
  // 概览
  getOverview(params) {
    return get('/emqx/site/overview', params, )
  },
  // 时间
  getSystemTime(params) {
    return get('/getSystemTime', params, )
  },
  // =================================================站点和设备接口==========================================================
  // 删除站点
  getzdspDelete(params) {
    return del('/emqx/site', params)
  },
  // 查询站点列表
  getzdspList(params) {
    return get('/emqx/site/list', params)
  },
  // 修改是否自动作业
  getzdspSetAutoWork(params) {
    return put('/emqx/site/setAutoWork', params)
  },
  // 启动作业任务
  getzdspStartWork(params) {
    return post('/emqx/site/startWork', params)
  },
  // 新增站点
  getzdspSiteAdd(params) {
    return post('/emqx/site', params)
  },
  // 修改站点
  getzdspSiteEdit(params) {
    return put('/emqx/site', params)
  },
  // 获取站点详细信息
  getzdspSiteEditDetail(params) {
    return get('emqx/site/' + params, )
  },
  // 设备编号查询型号
  getzdspModelByRobotId(params) {
    return get('/emqx/site/getModelByRobotId', params)
  },
  // 设置自动运行周期
  getSetAutoWork(params) {
    return post('/emqx/site/setAutoWork', params)
  },
  // 通过robotId获取履带式配置详细信息
  getConfigByRobotId(params) {
    return get('/emqx/trackConfig/getConfigByRobotId/' + params)
  },
  // 修改履带式
  trackConfig(params) {
    return put('/emqx/trackConfig', params)
  },
  // 通过robotId获取跨排式配置详细信息
  getConfigKpsByRobotId(params) {
    return get('/emqx/crossConfig/getConfigByRobotId/' + params)
  },
  // 修改跨排式配置
  getCrosskpsConfig(params) {
    return put('/emqx/crossConfig', params)
  },
  // 场景类型
  fixedPvModuleTree(params) {
    return get('/emqx/trackConfig/fixedPvModuleTree', params)
  },
  // 清洁模式级联
  cleanOperationTree(params) {
    return get('/emqx/trackConfig/cleanOperationTree', params)
  },
  // 获取固件详情
  getFirmware(params) {
    return get('/emqx/trackConfig/getFirmware/' + params)
  },
  // 升级固件
  updateFirmware(params) {
    return get('/emqx/trackConfig/updateFirmware/' + params)
  },
  // 站点详情
  siteDetail(params) {
    return get('/emqx/site/siteDetail', params)
  },
  // 通用图表接口
  chartOpen(params) {
    return get('/emqx/site/chart', params)
  },
  // 站点主人
  equipmentMaster(params) {
    return get('/emqx/site/equipmentMaster/' + params)
  },
  // 底图列表
  getMapGridList(params) {
    return get('/emqx/site/getMapGridList', params)
  },
  // 终止作业任务
  getzdspStopWork(params) {
    return post('/emqx/site/stopWork', params)
  },
  // 开启设备探路模式
  getzdspStartExplore(params) {
    return post('/emqx/site/startExplore', params)
  },
  //补充
  getstartExploreAdd(params) {
    return post('/emqx/site/startExploreAdd', params)
  },
  // 获取设备路径列表
  getRobotGridList(params) {
    return get('/emqx/site/getRobotGridList', params)
  },
  // 放弃绘图
  abandonMapping(params) {
    return get('/emqx/site/abandonMapping', params)
  },
  //保存绘图 
  saveMapping(params) {
    return get('/emqx/site/saveMapping', params)
  },
  // 停止设备探路模式
  stopExplore(params) {
    return post('/emqx/site/stopExplore', params)
  },
  // 告警通知
  isSendSms(params) {
    return get('/emqx/userRobotSms/isSendSms', params)
  },
  // 双击网格事件
  editGrid(params) {
    return post('/emqx/site/editGrid', params)
  },
  // 获取实时配置
  getSet(params) {
    return get('/emqx/site/getSet?robotId=' + params)
  },
  // 获取实时状态
  getSetStatus(params) {
    return get('/emqx/site/getSetStatus?robotId=' + params)
  },
  // 跨排无融合网格
  gridCrossNoFusion(params) {
    return get('/emqx/site/gridCrossNoFusion?robotId=' + params)
  },
  // 电压
  getVoltage(params,type) {
    return get('/emqx/site/getVoltage?robotId=' + params +'&type='+type)
  },
  // =================================================作业日志接口==========================================================
  // 作业日志列表
  getyxrzList(params) {
    return get('/emqx/workLog/list', params)
  },
  // =================================================设备记录接口==========================================================
  // 设备记录列表
  getxtrzList(params) {
    return get('/emqx/robotLogin/list', params)
  },
  // =================================================热斑日志接口==========================================================
  // 热斑日志列表
  getrbList(params) {
    return get('/emqx/fusionHotSpotData/list', params)
  },
  // 热斑网格
  getRobotGridListDetail(params) {
    return get('/emqx/fusionHotSpotGrid/getRobotGridList?hotSpotId='+params)
  },
  // =================================================账号管理接口==========================================================
  // 获取用户列表
  gezhglList(params) {
    return get('/emqx/user/list', params)
  },
  // 新增用户
  gezhglUser(params) {
    return post('/system/user', params)
  },
  // 用户账号筛选角色列表
  getRoleList(params) {
    return get('/emqx/user/getRoleList', params)
  },
  // 删除用户
  getzhglDelete(params) {
    return del('/system/user/' + params)
  },
  // 修改用户
  gezhglEditUser(params) {
    return put('/system/user', params)
  },
  // 根据用户编号获取详细信息
  getzhglDetail(params) {
    return get('/system/user/' + params, )
  },
  // 修改其他人账号密码
  getEditOtherPassword(params) {
    return get('/system/user/editOtherPassword', params, )
  },
  // 设备分配弹窗获取设备列表
  getRobotList(params) {
    return get('/emqx/userRobot/getRobotList', params, )
  },
  // 新增设备分配
  getuserRobot(params) {
    return get('/emqx/userRobot', params, )
  },
  // 分配站点和取消
  getdistributionSite(params) {
    return post('/emqx/userRobot/distributionSite', params, )
  },
  // 启动登录
  editStatus(params) {
    return post('/system/user/editStatus', params, )
  },
  // =================================================设备入网接口==========================================================
  // 查询设备列表
  getsbrwList(params) {
    return get('/emqx/robot/list', params, )
  },
  // 新增设备
  getsbrwRobot(params) {
    return post('/emqx/robot', params, )
  },
  // 获取设备详细信息
  getsbrwDetail(params) {
    return get('/emqx/robot/' + params)
  },
  // 修改设备
  getsbrwEditRobot(params) {
    return put('/emqx/robot', params, )
  },
  // 删除设备
  getsbrwDelete(params) {
    return del('/emqx/robot/' + params)
  },

  // =================================================区域设置接口==========================================================
  // 列表
  getAreacolumn(params) {
    return get('/emqx/area/column', params, )
  },
  //删除区域
  getDlearea(params) {
    return post('/emqx/area/delete', params, )
  },
  //  新增区域
  getAddarea(params) {
    return post('/emqx/area', params, )
  },
  // 修改排序
  editSortNum(params) {
    return put('/emqx/area/editSortNum', params, )
  },
}