var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[(_vm.logoName == '杭电制造')?_c('img',{staticClass:"hdzz",attrs:{"src":require("@/assets/home/hdzz.png")}}):_vm._e(),(_vm.logoName == '光源智能')?_c('img',{staticClass:"gyzn",attrs:{"src":require("@/assets/home/logo-GYZN.jpg")}}):_vm._e(),_c('div',{staticClass:"tabs"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"list",class:{
            activeLi: _vm.currentPage ? _vm.currentPage == index : _vm.num == index,
          },on:{"click":function($event){return _vm.tabChange(index)}}},[_c('div',{staticClass:"list_tab"},[(index == 4 && _vm.num == 4)?_c('i',{class:item.icon,staticStyle:{"font-size":"18px","color":"red"}}):_c('i',{class:item.icon,staticStyle:{"font-size":"18px"}}),_c('p',[_vm._v(" "+_vm._s(item.name)+" "),(index == 4 && _vm.num != 4)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(_vm._s(_vm.total))]):_vm._e(),(index == 4 && _vm.num == 4)?_c('span',{staticStyle:{"margin-left":"3px","color":"red"}},[_vm._v(_vm._s(_vm.total))]):_vm._e()])]),_c('div',{staticClass:"xian"})])}),0)]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.currentDate))]),(
          (_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'superadmin') ||
          (_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'countyadmin')
        )?_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-setting",staticStyle:{"margin-right":"5px","font-weight":"bold","font-size":"18px","color":"#fff"}}),_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(" 系统设置")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'countyadmin')?_c('el-dropdown-item',{attrs:{"command":"4"}},[_vm._v("新增站点")]):_vm._e(),(_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'superadmin')?_c('el-dropdown-item',{attrs:{"command":"1"}},[_vm._v("区域设置")]):_vm._e(),(
              (_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'superadmin') ||
              (_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'countyadmin')
            )?_c('el-dropdown-item',{attrs:{"command":"2"}},[_vm._v("账号管理")]):_vm._e(),(_vm.userInfo && _vm.userInfo.roles[0].roleKey == 'superadmin')?_c('el-dropdown-item',{attrs:{"command":"3"}},[_vm._v("设备入网")]):_vm._e()],1)],1):_vm._e(),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handlePersonal}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-user",staticStyle:{"font-weight":"bold","font-size":"18px","cursor":"pointer","color":"#fff"}}),_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("个人中心")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"4"}},[_vm._v("账号信息")]),_c('el-dropdown-item',{attrs:{"command":"3"}},[_vm._v("修改手机号码")]),_c('el-dropdown-item',{attrs:{"command":"1"}},[_vm._v("修改密码")]),_c('el-dropdown-item',{attrs:{"command":"2"}},[_vm._v("退出登录")])],1)],1)],1)]),_c('div',{staticClass:"auto"},[_c('div',{staticClass:"content"},[_c('router-view')],1)]),_c('addSiteForm',{ref:"addSiteFormRef",on:{"event-name":_vm.parentMethod}}),_c('editPassword',{ref:"editPasswordRef",on:{"event-name":_vm.parentMethodAccount}}),_c('accountMessage',{ref:"accountMessageRef"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }