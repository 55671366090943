<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="dialogVisible"
      size="500px"
      :wrapperClosable="false"
      :before-close="handleClose"
    >
      <div class="drawer">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="站名名称" prop="siteName">
            <el-input
              v-model="form.siteName"
              placeholder="请输入站名名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="站点编号" prop="siteCode">
            <el-input
              v-model="form.siteCode"
              @input="handleInput"
              placeholder="请输入站点编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="robotId">
            <el-input
              v-model="form.robotId"
              style="margin-top: 3px; width: 100%"
              placeholder="请输入设备编号"
              @input="changeID"
            >
              <!-- <el-button @click="search" slot="append" type="primary"
                >查询</el-button
              > -->
            </el-input>
            <div class="inputItem">
              <el-button
                :type="form.robotId ? 'primary' : 'info'"
                @click="search"
                >校验</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="设备类型和型号" prop="robotType">
            <el-input
              v-model="form.robotType"
              placeholder="请先查询设备编号"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="机器人主板通信方式"
            prop="isPassthrough"
            v-if="form.type != '履带式' && form.fusionMainboard"
          >
            <el-select
              v-model="form.isPassthrough"
              placeholder="请选择机器人主板通信方式"
              style="width: 100%"
            >
              <el-option
                label="非透传模式（物联网模块独立通信）"
                value="0"
              ></el-option>
              <el-option
                label="透传模式（采用232有线经过融合主板通信）"
                value="1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input
              v-model="form.address"
              placeholder="请输入地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="单个光伏板长度(mm)" prop="length">
            <el-input
              v-model="form.length"
              placeholder="请输入单个光伏板长度"
            ></el-input>
          </el-form-item>
          <el-form-item label="单个光伏板宽度(mm)" prop="width">
            <el-input
              v-model="form.width"
              placeholder="请输入单个光伏板宽度"
            ></el-input>
          </el-form-item>
          <el-form-item label="装机总容量(兆瓦)" prop="installedCapacity">
            <el-input
              v-model="form.installedCapacity"
              placeholder="请输入装机总容量(兆瓦)"
            ></el-input>
          </el-form-item>
          <el-form-item label="旋转角度" prop="angle">
            <el-input
              v-model="form.angle"
              placeholder="请输入旋转角度"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="地图位置" prop="map">
            <el-input disabled v-model="form.map" placeholder="请选择地图位置"></el-input>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="drawer-footer">
          <el-button type="primary" @click="admin" :loading="isLoading">
            {{ isLoading ? "保存中" : "保存" }}</el-button
          >
          <el-button @click="handleClose">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      form: {},
      rules: {
        siteName: [
          { required: true, message: "请输入站名名称", trigger: "blur" },
        ],
        siteCode: [
          { required: true, message: "请输入站点编号", trigger: "blur" },
        ],
        robotId: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
        ],
        robotType: [
          { required: true, message: "请先查询设备编号", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        length: [
          { required: true, message: "请输入单个光伏板长度", trigger: "blur" },
        ],
        width: [
          { required: true, message: "请输入单个光伏板宽度", trigger: "blur" },
        ],
        installedCapacity: [
          { required: true, message: "请输入装机总容量", trigger: "blur" },
        ],
        isPassthrough: [
          {
            required: true,
            message: "请选择机器人主板通信方式",
            trigger: "blur",
          },
        ],
        angle: [{ required: true, message: "请输入选择角度", trigger: "blur" }],
      },
      isLoading: false,
    };
  },
  methods: {
    show(val, name, row) {
      this.form = {};
      this.title = name;
      this.dialogVisible = true;
      if (name == "修改") {
        this.getzdspSiteEditDetail(row);
      }
      console.log(this.form.robotId, "c");
    },
    handleInput(value) {
      // 使用正则表达式匹配非汉字和特殊字符，并替换为空字符串
      this.form.siteCode = value.replace(/[\u4e00-\u9fa5]|[^\w\s]/g, "");
      if (this.form.siteCode) {
        this.form.siteCode = this.form.siteCode.replace(/\s/g, "");
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
      this.$refs.form.resetFields();
    },
    search() {
      if (this.form.robotId == undefined) {
        this.$message({
          type: "warning",
          message: "请先输入设备编号在查询!",
        });
      } else {
        this.getzdspModelByRobotId();
      }
    },
    // 详情
    getzdspSiteEditDetail(row) {
      this.$http.getzdspSiteEditDetail(row.id).then((res) => {
        if (res && res.code == 200) {
          this.form = res.data;
          this.form.type = this.form.robotType;
          this.form.robotType = res.data.robotType + "/" + res.data.robotModel;
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    //设备编号查询型号
    getzdspModelByRobotId() {
      let params = {
        robotId: this.form.robotId,
      };
      this.$http.getzdspModelByRobotId(params).then((res) => {
        if (res && res.code == 200) {
          if (res.data) {
            res.data.siteName = this.form.siteName;
            res.data.siteCode = this.form.siteCode;
            res.data.address = this.form.address;
            this.$nextTick(() => {
              // DOM已经更新
              if (this.title == "新建站点") {
                res.data.address = this.form.address;
                res.data.installedCapacity = this.form.installedCapacity;
                res.data.width = this.form.width;
                res.data.length = this.form.length;
                this.form = res.data;
              }
              this.form.robotType = res.data.type + "/" + res.data.model;
            });

            this.$message.success("查询成功");
          }
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 提交
    admin() {
      this.$refs.form.validate((valid) => {
        if (this.title == "新建站点") {
          if (valid) {
            let obj = {
              address: this.form.address,
              siteName: this.form.siteName,
              siteCode: this.form.siteCode,
              robotId: this.form.robotId,
              width: this.form.width,
              length: this.form.length,
              installedCapacity: this.form.installedCapacity,
              isPassthrough: this.form.isPassthrough,
              angle: this.form.angle,
            };
            this.$confirm("确定要新建站点吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.isLoading = true;
                this.$http
                  .getzdspSiteAdd(obj)
                  .then((res) => {
                    console.log(res);
                    if (res && res.code == 200) {
                      this.isLoading = false;
                      this.$message.success(res.msg);
                      this.$emit("event-name");
                      this.dialogVisible = false;
                    } else if (res && res.code != 200) {
                      this.isLoading = false;
                      this.$message.error(res.msg);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消新建站点",
                });
              });
          } else {
            console.log("error");
            return false;
          }
        } else {
          if (valid) {
            this.$confirm("确定要修改吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.isLoading = true;
                this.$http
                  .getzdspSiteEdit(this.form)
                  .then((res) => {
                    console.log(res);
                    if (res && res.code == 200) {
                      this.isLoading = false;
                      this.$message.success(res.msg);
                      this.$emit("event-name");
                      this.dialogVisible = false;
                    } else if (res && res.code != 200) {
                      this.isLoading = false;
                      this.$message.error(res.msg);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消修改",
                });
              });
          } else {
            console.log("error");
            return false;
          }
        }
      });
    },
    changeID(val) {
      this.form.robotType = "";
      this.form.robotId = val.replace(/\s/g, "");
    },
  },
};
</script>

<style lang="less" scoped>
.inputItem {
  position: absolute;
  top: 28px;
  right: 3px;
  /deep/.el-button {
    padding: 5px 12px;
    border-radius: 2px;
    margin-left: 20px;
  }
  /deep/.el-button--info {
    color: #fff;
    background-color: #abadb3;
    border-color: #dcdee3;
  }
}
</style>
