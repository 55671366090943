
import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
 
export default new Vuex.Store({
  state: {
    currentPage: '' // 假设默认页面
  },
  mutations: {
    setCurrentPage(state, newPage) {
      state.currentPage = newPage;
    }
  }
});