<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="dialogVisible"
      size="500px"
      :before-close="handleClose"
      :wrapperClosable="false"
    >
      <div class="drawer">
        <el-form ref="form">
          <el-form-item label="账号" prop="userName">
            <el-input
              v-model="form.userName"
              placeholder="请输入账号"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="nickName">
            <el-input
              v-model="form.nickName"
              placeholder="请输入姓名"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleName">
            <el-input
              v-model="form.roleName"
              placeholder="请输入角色"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="归属区域" prop="areaName">
            <el-input
              v-model="form.areaName"
              placeholder="请输入归属区域"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phonenumber">
            <el-input
              v-model="form.phonenumber"
              placeholder="请输入手机号码"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="userName">
            <el-input
              v-model="form.createTime"
              placeholder="请输入创建时间"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>
        
        <script>
import CryptoJS from "crypto-js";
import router from "@/router";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      rowList: {},
      formData: [],
      form: {},
    };
  },
  created() {
    // this.form = JSON.parse(sessionStorage.getItem("userInfo"));
    this.form.areaName = this.form.areaName ? this.form.areaName : "/";
  },
  methods: {
    // 弹框显示
    show(val, name) {
      this.dialogVisible = true;
      this.title = name;
      this.getInfo();
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
    },
    // 获取用户信息
    getInfo() {
      this.$http.getInfo().then((res) => {
        if (res && res.code == 200) {
          this.form = res.data.user;
          this.form.areaName = this.form.areaName ? this.form.areaName : "/";
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
        
        <style lang="less" scoped>
.content {
  .name {
    font-size: 13px;
    padding: 10px 0;
    border-bottom: 1px solid #dad6d6;
    span {
      width: 70px;
      display: inline-block;
      // text-align: center;
    }
  }
}
</style>