import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import store from '../store';
Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'home', //首页
    component: HomeView,
    children: [{
        path: '/sites-and-equipment',
        name: 'sites-and-equipment',
        meta: {
          title: '站点和设备'
        },
        component: () => import( /* webpackChunkName: "sitesAndEquipment" */ '../views/home/sitesAndEquipment.vue')
      },
      {
        path: '/run-log',
        name: '作业日志',
        meta: {
          title: '作业日志'
        },
        component: () => import( /* webpackChunkName: "runLog" */ '../views/home/runLog.vue')
      },
      {
        path: '/system-log',
        name: '设备记录',
        meta: {
          title: '设备记录'
        },
        component: () => import( /* webpackChunkName: "heartbeatLog" */ '../views/home/systemLog.vue')
      },
      {
        path: '/hot-spot-warning',
        name: 'hot-spot-warning',
        meta: {
          title: '热斑告警'
        },
        component: () => import( /* webpackChunkName: "hotSpotWarning" */ '../views/home/hotSpotWarning.vue')
      },
      {
        path: '/system-settings/device-access-to-the-network',
        name: '系统设置/设备入网',
        meta: {
          title: '系统设置/设备入网'
        },
        component: () => import( /* webpackChunkName: "sySdevicePresets" */ '../views/home/sySnetwork.vue')
      },
      {
        path: '/system-settings/account-settings',
        name: '系统设置/账号设置',
        meta: {
          title: '系统设置/账号设置'
        },
        component: () => import( /* webpackChunkName: "sySaccountSettings" */ '../views/home/sySaccountSettings.vue')
      },
      {
        path: '/system-settings/regional-settings',
        name: '系统设置/区域设置',
        meta: {
          title: '系统设置/区域设置'
        },
        component: () => import( /* webpackChunkName: "syRegionalSettings" */ '../views/home/syRegionalSettings.vue')
      },
      {
        path: '/sites-and-equipment/equipment-operation-details',
        name: '站点和设备详情',
        meta: {
          title: '站点和设备详情'
        },
        component: () => import( /* webpackChunkName: "equipmentOperationDetails" */ '../views/home/equipmentOperationDetails.vue')
      },
      {
        path: '/overview',
        name: '概览',
        meta: {
          title: '概览'
        },
        component: () => import( /* webpackChunkName: "overview" */ '../views/home/overview.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'Login', //登录页
    name: '登录',
    meta: {
      title: '登录'
    },
    component: () => import( /* webpackChunkName: "admin" */ '../views/Login/loginAdmin.vue')
  },
]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  routes: routes
})

// 获取原型对象push函数
const VueRouterPush = VueRouter.prototype.push
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
router.beforeEach((to, from, next) => {
  console.log(to,"11")
  if (to.name == 'sites-and-equipment') {
    store.commit('setCurrentPage', 1);
  } else if (to.name == 'hot-spot-warning') {
    store.commit('setCurrentPage', 4);
  } else {
    store.commit('setCurrentPage', '');
  }

  // document.title = to.meta.title == '光伏组件清扫机器人系统' ? to.meta.title : to.meta.title + '-光伏组件清扫机器人系统'
  document.title = to.meta.title ? to.meta.title + '-光伏组件清扫机器人系统' : '光伏组件清扫机器人系统'
  // to.matched 是一个数组（匹配到是路由记录）
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    if (!store.state.user) {
      // 跳转到登录页面
      next({
        name: '/',
        query: { // 通过 url 传递查询字符串参数
          redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
        }
      })
    } else {
      next() // 允许通过
    }
  } else {
    next() // 允许通过
  }
})