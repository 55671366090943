<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="dialogVisible"
      size="500px"
      :before-close="handleClose"
      :wrapperClosable="false"
    >
      <div class="drawer">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item
            v-if="title == '修改密码' || title == '重置密码'"
            label="设置新密码"
            prop="password"
          >
            <el-input
              v-model="form.password"
              type="password"
              v-if="showPass == 'close'"
              placeholder="请输入新密码"
            ></el-input>
            <el-input
              v-model="form.password"
              v-if="showPass == 'open'"
              placeholder="请输入新密码"
            ></el-input>
            <img
              class="yj"
              @click="passChange('close')"
              v-if="showPass == 'open'"
              style="width: 20px; height: 20px"
              src="@/assets/home/open.png"
            />
            <img
              class="yj"
              @click="passChange('open')"
              v-if="showPass == 'close'"
              style="width: 20px; height: 20px"
              src="@/assets/home/close.png"
            />
          </el-form-item>
          <!-- <el-form-item
            v-if="title == '修改密码' || title == '重置密码'"
            label="确认密码"
            prop="newPassword"
          >
            <el-input v-model="form.newPassword"></el-input>
          </el-form-item> -->
          <el-form-item
            v-if="title == '修改手机号码'"
            label="手机号码"
            prop="phonenumber"
          >
            <el-input
              v-model="form.phonenumber"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="drawer-footer">
          <el-button type="primary" @click="save" :loading="isLoading">{{
            isLoading ? "保存中" : "保存"
          }}</el-button>
          <el-button @click="handleClose">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
    
    <script>
import CryptoJS from "crypto-js";
import router from "@/router";
export default {
  data() {
    return {
      dialogVisible: false,
      value1: new Date(2016, 9, 10, 18, 40),
      form: {},
      rules: {
        newPassword: [
          { required: true, message: "请输入再次输入新密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        phonenumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
      },
      title: "",
      rowList: {},
      showPass: "close",
      isLoading: false,
    };
  },
  methods: {
    show(val, name, row) {
      this.rowList = row;
      this.title = name;
      if (this.title == "修改手机号码") {
        this.getInfo();
      } else {
        this.form = {};
        if (this.$refs.form !== undefined) this.$refs.form.resetFields();
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.form = {};
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    // 眼睛
    passChange(val) {
      this.showPass = val;
    },
    // 获取用户信息
    getInfo() {
      this.$http.getInfo().then((res) => {
        if (res && res.code == 200) {
          this.form = res.data.user;
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 提交
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.title == "修改密码") {
            let obj = {};
            obj.password = CryptoJS.MD5(this.form.password).toString();
            obj.passwordPlaintext = this.form.password;
            this.$confirm("确定要修改密码吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.isLoading = true;
                this.$http
                  .getEditPassword(obj)
                  .then((res) => {
                    if (res && res.code == 200) {
                      this.$confirm(
                        "密码修改成功,需要退出系统重新登录!",
                        "提示",
                        {
                          confirmButtonText: "重新登录",
                          type: "warning",
                          showCancelButton: false,
                          closeOnClickModal: false,
                        }
                      )
                        .then(() => {
                          this.isLoading = false;
                          this.dialogVisible = false;
                          // 退出登录
                          this.getLogout();
                        })
                        .catch(() => {
                          this.isLoading = false;
                          this.dialogVisible = false;
                          // 退出登录
                          this.getLogout();
                        });
                    } else if (res && res.code != 200) {
                      this.isLoading = false;
                      this.$message.error(res.msg);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消修改密码",
                });
              });
          } else if (this.title == "修改手机号码") {
            let obj = {};
            obj.phonenumber = this.form.phonenumber;
            this.$confirm("确定要修改手机号码吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.isLoading = true;
                this.$http
                  .getEditPhonenumber(obj)
                  .then((res) => {
                    if (res && res.code == 200) {
                      this.isLoading = false;
                      this.$message.success(res.msg);
                      this.dialogVisible = false;
                      this.$emit("event-name");
                    } else if (res && res.code != 200) {
                      this.isLoading = false;
                      this.$message.error(res.msg);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消修改手机号码",
                });
              });
          } else {
            // if (this.form.password != this.form.newPassword) {
            //   this.$message.warning("两次密码不一致,请重新输入！");
            // } else {
            let obj = {};
            obj.password = CryptoJS.MD5(this.form.password).toString();
            obj.passwordPlaintext = this.form.password;
            obj.userId = this.rowList.userId;
            this.isLoading = true;
            this.$http
              .getEditOtherPassword(obj)
              .then((res) => {
                if (res && res.code == 200) {
                  this.isLoading = false;
                  this.$emit("event-name");
                  this.$message.success(res.msg);
                  this.dialogVisible = false;
                } else if (res && res.code != 200) {
                  this.isLoading = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            // }
          }
        } else {
          console.log("error");
          return false;
        }
      });
    },
    // 退出登录
    getLogout() {
      this.$http.getLogout().then((res) => {
        if (res && res.code == 200) {
          sessionStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("routeSiteDetail");
          // 跳转到登录页
          window.location.href = "/";

          this.$message({
            type: "success",
            message: "退出成功!",
          });
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
.inputNum {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .inputOne {
    margin-left: -73px;
    margin-right: 20px;
  }
  .inputOne2 {
    margin-left: -99px;
    margin-right: 20px;
  }
}
.inputItem {
  display: flex;
  justify-content: space-between;
}
.yj {
  position: absolute;
  top: 36px;
  cursor: pointer;
  right: 6px;
}
</style>