<template>
  <div class="home">
    <div class="header">
      <div class="left">
        <!-- <img src="@/assets/home/gjdw.png" /> -->
        <img
          class="hdzz"
          v-if="logoName == '杭电制造'"
          src="@/assets/home/hdzz.png"
        />
        <img
          class="gyzn"
          v-if="logoName == '光源智能'"
          src="@/assets/home/logo-GYZN.jpg"
        />
        <div class="tabs">
          <div
            class="list"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{
              activeLi: currentPage ? currentPage == index : num == index,
            }"
            @click="tabChange(index)"
          >
            <div class="list_tab">
              <i
                :class="item.icon"
                v-if="index == 4 && num == 4"
                style="font-size: 18px; color: red"
              ></i>
              <i :class="item.icon" v-else style="font-size: 18px"></i>
              <p>
                {{ item.name }}
                <span v-if="index == 4 && num != 4" style="margin-left: 3px">{{
                  total
                }}</span>
                <span
                  v-if="index == 4 && num == 4"
                  style="margin-left: 3px; color: red"
                  >{{ total }}</span
                >
              </p>
            </div>
            <div class="xian"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <span class="time">{{ currentDate }}</span>
        <el-dropdown
          @command="handleCommand"
          trigger="click"
          v-if="
            (userInfo && userInfo.roles[0].roleKey == 'superadmin') ||
            (userInfo && userInfo.roles[0].roleKey == 'countyadmin')
          "
        >
          <span class="el-dropdown-link">
            <i
              class="el-icon-setting"
              style="
                margin-right: 5px;
                font-weight: bold;
                font-size: 18px;
                color: #fff;
              "
            ></i>

            <span style="color: #fff"> 系统设置</span>
            <!-- {{ userInfo.roles[0].roleKey }} -->
          </span>
          <el-dropdown-menu slot="dropdown">
            <!--新增站点只有区县管理员可操作 -->
            <el-dropdown-item
              command="4"
              v-if="userInfo && userInfo.roles[0].roleKey == 'countyadmin'"
              >新增站点</el-dropdown-item
            >
            <el-dropdown-item
              command="1"
              v-if="userInfo && userInfo.roles[0].roleKey == 'superadmin'"
              >区域设置</el-dropdown-item
            >
            <el-dropdown-item
              command="2"
              v-if="
                (userInfo && userInfo.roles[0].roleKey == 'superadmin') ||
                (userInfo && userInfo.roles[0].roleKey == 'countyadmin')
              "
              >账号管理</el-dropdown-item
            >
            <el-dropdown-item
              command="3"
              v-if="userInfo && userInfo.roles[0].roleKey == 'superadmin'"
              >设备入网</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown @command="handlePersonal" trigger="click">
          <span class="el-dropdown-link">
            <i
              style="
                font-weight: bold;
                font-size: 18px;
                cursor: pointer;
                color: #fff;
              "
              class="el-icon-user"
            >
            </i>
            <span style="color: #fff">个人中心</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="4">账号信息</el-dropdown-item>
            <el-dropdown-item command="3">修改手机号码</el-dropdown-item>
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="auto">
      <!-- :style="{ height: innerHeight - 120 + 'px' }" -->
      <div class="content">
        <router-view />
      </div>
    </div>
    <addSiteForm ref="addSiteFormRef" @event-name="parentMethod"></addSiteForm>
    <editPassword ref="editPasswordRef" @event-name="parentMethodAccount" />
    <accountMessage ref="accountMessageRef" />
  </div>
</template>

<script>
import addSiteForm from "./addSiteForm.vue";
import editPassword from "./editPasswordForm.vue";
import accountMessage from "./accountMessage.vue";
import websocket from "@/api/websocket.js";
export default {
  name: "HomeView",
  components: {
    addSiteForm,
    editPassword,
    accountMessage,
  },
  data() {
    return {
      tabList: [
        {
          name: "概览",
          icon: "el-icon-data-analysis",
        },
        {
          name: "站点和设备",
          icon: "el-icon-cpu",
        },
        {
          name: "作业日志",
          icon: "el-icon-notebook-1",
        },
        {
          name: "设备记录",
          icon: "el-icon-notebook-2",
        },
        {
          name: "热斑告警",
          icon: "el-icon-warning",
        },
      ],
      num: 0,
      innerHeight: window.innerHeight,
      userInfo: {},
      logoName: "",
      total: 0,
      dqTime: "",
      currentDateTime: "",
      currentDate: "",
      timer: null, // 存储计时器
      isBrowserTabActive: true,
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.num = sessionStorage.getItem("pageIndex");
    window.addEventListener("popstate", this.handleBackButton);
    if (config.VUE_LOGO) {
      this.logoName = config.VUE_LOGO ? config.VUE_LOGO : "";
    }
    // this.getSystemTime(); //当前时间
    this.getrbList();
    this.timer = setInterval(() => {
      this.formatDate();
    }, 1000);
  },
  mounted() {
    // this.isBrowserTabActive = document.visibilityState === "visible";
    // document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },

  computed: {
    currentPage() {
      return this.$store.state.currentPage;
    },
  },
  // destroyed() {
  //   // 移除监听器
  //   document.removeEventListener(
  //     "visibilitychange",
  //     this.handleVisibilityChange
  //   );
  // },
  methods: {
    // handleVisibilityChange(val) {
    //   if (document.visibilityState === "visible") {
    //     this.getSystemTime(); //当前时间
    //     this.isBrowserTabActive = true;
    //   } else {
    //     this.isBrowserTabActive = false;
    //     this.clearTimer();
    //   }
    // },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.currentDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    handleBackButton(event) {
      // 这里编写你想要执行的逻辑
      if (event && event.target.location.pathname == "/sites-and-equipment") {
        this.num = 1;
        sessionStorage.setItem("pageIndex", 1);
      } else if (event && event.target.location.pathname == "/run-log") {
        this.num = 2;
        sessionStorage.setItem("pageIndex", 2);
        // this.$router.push("/run-log");
      } else if (event && event.target.location.pathname == "/system-log") {
        this.num = 3;
        sessionStorage.setItem("pageIndex", 3);
      } else if (
        event &&
        event.target.location.pathname == "/hot-spot-warning"
      ) {
        this.num = 4;
        sessionStorage.setItem("pageIndex", 4);
      } else if (event && event.target.location.pathname == "/overview") {
        this.num = 4;
        sessionStorage.setItem("pageIndex", 0);
      }
      // console.log('浏览器返回按钮被点击了',event.target.location.pathname);
    },
    parentMethod() {
      window.location.href = "/sites-and-equipment";
    },
    parentMethodAccount() {
      if (this.$route.path == "/system-settings/account-settings") {
        window.location.href = "/system-settings/account-settings";
      }
    },
    // 页面切换
    tabChange(index) {
      localStorage.removeItem("routeSiteDetail");
      if (index == 1) {
        this.num = index;
        sessionStorage.setItem("pageIndex", 1);
        this.$router.push("/sites-and-equipment");
      } else if (index == 2) {
        this.num = index;
        sessionStorage.setItem("pageIndex", 2);
        this.$router.push("/run-log");
      } else if (index == 3) {
        this.num = index;
        sessionStorage.setItem("pageIndex", 3);
        this.$router.push("/system-log");
      } else if (index == 4) {
        this.num = index;
        sessionStorage.setItem("pageIndex", 4);
        this.$router.push("/hot-spot-warning");
      } else {
        this.num = index;
        sessionStorage.setItem("pageIndex", 0);
        this.$router.push("/overview");
      }
    },
    // 热斑告警
    getrbList() {
      let params = {
        isCurrent: 0,
      };

      this.$http.getrbList(params).then((res) => {
        if (res && res.code == 200) {
          this.total = res.total;
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 时间
    // getSystemTime() {
    //   this.$http.getSystemTime().then((res) => {
    //     if (res && res.code == 200) {
    //       this.test(new Date(res.data));
    //     } else if (res && res.code != 200) {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    // test(date) {
    //   this.timer = setInterval(() => {
    //     let addtime = new Date(date.setSeconds(date.getSeconds() + 1));
    //     this.dqTime =
    //       addtime.getFullYear() +
    //       "-" +
    //       (addtime.getMonth() + 1).toString().padStart(2, "0") +
    //       "-" +
    //       addtime.getDate().toString().padStart(2, "0");

    //     this.currentDateTime =
    //       addtime.getHours().toString().padStart(2, "0") +
    //       ":" +
    //       addtime.getMinutes().toString().padStart(2, "0") +
    //       ":" +
    //       addtime.getSeconds().toString().padStart(2, "0");
    //   }, 1000);
    // },
    // clearTimer() {
    //   if (this.timer) {
    //     clearInterval(this.timer);
    //     this.timer = null;
    //   }
    // },
    // beforeDestroy() {
    //   this.clearTimer();
    // },
    handleCommand(index) {
      this.num = -1;
      sessionStorage.removeItem("pageIndex");
      if (index == 1) {
        this.$router.push("/system-settings/regional-settings");
      } else if (index == 2) {
        this.$router.push("/system-settings/account-settings");
      } else if (index == 3) {
        this.$router.push("/system-settings/device-access-to-the-network");
      } else if (index == 4) {
        this.$refs.addSiteFormRef.show(true, "新建站点");
      }
    },
    // 退出登录
    getLogout() {
      this.$http.getLogout().then((res) => {
        if (res && res.code == 200) {
          sessionStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("routeSiteDetail");
          // 跳转到登录页
          window.location.href = "/";

          this.$message({
            type: "success",
            message: "退出成功!",
          });
        } else if (res && res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 退出登录
    handlePersonal(index) {
      if (index == 1) {
        this.$refs.editPasswordRef.show(true, "修改密码");
      } else if (index == 2) {
        this.$confirm("确定要退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 退出登录
            this.getLogout();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
          });
      } else if (index == 3) {
        this.$refs.editPasswordRef.show(true, "修改手机号码");
      } else if (index == 4) {
        this.$refs.accountMessageRef.show(true, "账号信息");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  // background: #d6d6d6;
  width: 100%;
  // height: 100%;
  color: #3d3d3d;

  .header {
    height: 50px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    border-bottom: 1px solid #16f5e6 !important;
    .left {
      display: flex;
      align-items: center;
      .hdzz {
        width: 34px;
        height: 34px;
        margin-right: 33px;
      }
      .gyzn {
        width: 40px;
        margin-right: 33px;
      }
      .tabs {
        display: flex;
        align-items: center;
        margin-top: 3px;
        .list {
          margin-right: 33px;
          cursor: pointer;
          color: #fff;
          .list_tab {
            display: flex;
            align-items: center;
            p {
              margin-left: 8px;
              color: #fff;
              font-size: 16px;
              margin-bottom: 3px;
            }
            margin-bottom: 3px;
          }
          .xian {
            width: 100%;
            height: 4px;
          }
        }
        .activeLi {
          color: #16f5e6;
          .list_tab {
            margin-bottom: 4px;
            p {
              color: #16f5e6;
            }
          }
          .xian {
            width: 100%;
            height: 4px;
            background: #16f5e6;
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;

      div {
        font-size: 16px;
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .auto {
    overflow: auto;
    height: 94%;
    padding-bottom: 30px;
    // background: pink;
  }
  /deep/.auto::-webkit-scrollbar {
    // display: none;
  }
  .content {
    // background: #fff;
    // width: 1200px;
    // width: 100%;
    // margin: 16px;
    border-radius: 2px;
  }
}
.time {
  font-size: 20px;
  color: #16f5e6;
  margin-right: 20px;
  font-family: "Courier New", Courier, monospace;
}
</style>